.about-slider {
  position: relative;

  &:after {
    content: '';
    background-image: url("../../assets/images/a2.png");
    background-size: 100% 100%;
    height: 652px;
    width: 523px;
    display: block;
    position: absolute;
    right: 100px;
    top: -300px;
    z-index: 999999;

    @media (max-width: 450px) {
      //right: -65%;
      //top: 0;

      display: none;
    }
  }

  &-container {
    bottom: 150px;

    @media (max-width: 450px) {
      bottom: 100px;
    }
  }

  &-wrapper {
  }

  &-slide {
  }

  &-pagination {
    position: relative;
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .swiper-pagination-bullet {
      background: $main-theme-color;
      height: 15px;
      width: 15px;
      opacity: 1;

      &-active {
        opacity: 1;
        height: 20px;
        width: 20px;

      }
    }
  }
}