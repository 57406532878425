.section-index-blog {
  background-color: #E2E2E2;
  padding: 100px 0;

  .container {
    position: relative;
    z-index: 10;
    padding: 0 35px;
  }

  &__title {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 70px;
    text-transform: uppercase;
    color: $dark-color;
    margin-bottom: 35px;
  }

  &-list {
    display: flex;
    flex-direction: row;

    &__image-group {
      position: relative;
    }

    &__image {
      width: 100%;
    }

    &__date {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 10px;
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: $main-theme-color;
    }

    &__title {
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: $dark-color;
      margin-top: 35px;
      margin-bottom: 15px;
      display: block;

      &:hover {
        color: inherit;
      }
    }

    &__preview {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $dark-color;
      margin-bottom: 15px;
    }

    &__tags {
      a {
        color: $main-theme-color;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;

        &:before {
          content: "#";
          padding-right: 3px;
        }
      }
    }
  }

  &-control {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-more {
    display: inline-block;
    color: $main-theme-color;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    margin-right: 25.8%;

    @media (max-width: 450px) {
      margin-top: 80px;
    }

    &:hover {
      color: $main-theme-color;
    }

    &:after {
      position: absolute;
      right: -150%;
      top: 50%;
      width: 96px;
      height: 1px;
      content: "";
      display: block;
      background: $main-theme-color;
    }
  }

  &-pagination {
    width: 200px !important;

    .swiper-pagination-bullet {
      margin-right: 10px !important;
      width: 7px;
      height: 7px;

      &-actiive {
        width: 18px;
        height: 18px;
      }
    }
  }
}