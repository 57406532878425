.blog-list {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  padding-bottom: 140px;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  &__title {
    @extend .portfolio-list__title;
    color: $dark-color;
    display: block;

    &:hover {
      color: $dark-color;
    }
  }

  &__item {
    flex: 1 0 29.5%;
    margin: 20px;

    @media (max-width: 450px) {
      margin: 15px 5px;
    }

    &.large {
      flex: 1 0 100%;

      .blog-list__title {
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 59px;
      }

      .blog-list__image {
        height: 423px;
      }
    }
  }

  &__preview {
    @extend .portfolio-list__preview;
    color: #666;
  }

  &__image {
    position: relative;
    height: 170px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &:before {
      display: block;
      content: attr(data-date);
      position: absolute;
      color: $main-theme-color;
      bottom: 0;
      left: 0;
      margin: 10px;

      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &__tags {
    margin-top: 20px;
  }

}