.team {
  background: #333333;
  padding: 80px 0;

  h2 {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 128px;
    line-height: 128px;
    text-transform: uppercase;
    color: #FFFFFF;

    @media (max-width: 450px) {
      font-size: 48px;
      line-height: 48px;
    }
  }

  &-pagination {
    margin: 15px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .swiper-pagination-bullet {
      background: $main-theme-color;
      opacity: 1;
      width: 7px;
      height: 7px;
    }

    .swiper-pagination-bullet-active {
      width: 18px;
      height: 18px;
      background: $main-theme-color;
    }
  }
}

.team-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 80px;

  &__item {
    width: 33%;
    margin-bottom: 40px;

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  &__name {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: $main-theme-color;

    margin-top: 15px;
  }

  &__position {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }

  &__image {
    object-fit: cover;
    width: 184px;
    height: 104px;

    @media (max-width: 450px) {
      width: 100%;
      height: auto;
    }
  }
}