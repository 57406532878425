.header {
  position: relative;
  background: $main-theme-color;
  display: none;
  height: 103px;

  @media (min-width: 450px) {
    display: block;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 0;

    @media (max-width: 1360px) {
      max-width: 800px;
    }
    @media (max-width: 800px) {
      max-width: 800px;
      width: 80%;
      margin: auto 0 auto auto;
    }
  }

  .slogan {
    color: white;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-left: 35px;
  }

  .phone {
    margin-left: auto;
    color: white;
    margin-right: 35px;
    font-size: 18px;
  }

  .feedback-button {
    background: transparent;
    border-radius: 40px;
    display: block;
    width: 170px;
    padding: 10px;
    border: 1px white solid;
    color: white;
    margin-right: 35px;
    font-size: 14px;
    outline: none !important;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 2px 2px 8px 0 rgba(255, 255, 255, 0.5);
    }
  }

  &-sticky {
    &:not(.active) {
      position: sticky;
      top: 0;
      z-index: 999999;
    }
  }
}