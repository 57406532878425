.page {
  &.isBlack {
    background-color: $dark-color;

    .page-title {
      color: #FFFFFF;
    }
  }

  &-title {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 70px;
    text-transform: uppercase;
    padding: 50px 0;
    color: $dark-color;

    @media (max-width: 450px) {
      font-size: 48px;
      line-height: 48px;
    }

    &.detail-title {
      //max-width: 750px;
      width: 100%;
      font-weight: 900;
      font-size: 64px;
      line-height: 64px;
      //line-height: 1.5em;
      text-transform: uppercase;
      color: #FFFFFF;
      //margin-bottom: -240px;
      z-index: 6;
      position: relative;

      @media (max-width: 450px) {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: -270px;
      }
    }
  }

  &-content {
    background-color: white;
    padding: 200px 0 0 0;

    @media (max-width: 450px) {
      padding: 150px 0 0 0;
    }

    p:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      color: #666666;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      max-width: 800px;
    }
  }

  .container {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #666;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
      color: #666;
    }

    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }

    ul ul, ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;
    }

    ol ol, ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px;
    }
  }
}

.page-slider {
  &-container {
    position: relative;
    z-index: 5;
    margin-bottom: -100px;

    @media (max-width: 450px) {
      margin-top: 200px;
    }
  }

  &-slide {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-wrapper {
    margin-bottom: 10px;
  }

  &-label {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #EE4040;
    margin-right: 240px;

    @media (max-width: 450px) {
      margin: 0;
      margin-top: 20px;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      right: -200px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 100px;
      height: 1px;
      background: $main-theme-color;

      @media (max-width: 450px) {
        display: none;
        text-align: center;
        margin: 0 !important;
      }
    }
  }

  &-control {
    padding-top: 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }
}