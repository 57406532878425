.footer {
  padding: 100px 0;
  background-color: $main-theme-color;
  z-index: 3;
  position: relative;

  @media (max-width: 450px) {
    padding: 80px 0 20px 0;
  }

  .title {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 70px;
    text-transform: uppercase;
    color: white;

    @media (max-width: 450px) {
      font-size: 30px;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }

  .email {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 70px;
    text-transform: uppercase;
    color: white;

    @media (max-width: 450px) {
      font-size: 20px;
      line-height: 18px;
    }
  }

  &-contacts {
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;

    @media (max-width: 450px) {
      flex-direction: column;
      margin-bottom: 0;
    }

    &__item {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;

      @media (max-width: 450px) {
        margin-top: 10px;
      }

      &:not(:last-child) {
        margin-right: 80px;

        @media (max-width: 450px) {
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-col {
    flex: 1 0 16%;
    width: 16%;


    &:first-child {
      flex: 1 0 10%;
      width: 10%;
    }

    @media (max-width: 1300px) {
      flex: 0 0 33%;
      width: 33%;
      margin-bottom: 20px;


      &:first-child {
        flex: 0 0 33%;
        width: 33%;
      }
    }

    @media (max-width: 800px) {
      flex: 0 0 50%;
      width: 50%;
      margin-bottom: 20px;


      &:first-child {
        flex: 0 0 50%;
        width: 50%;
      }
    }
    @media (max-width: 450px) {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 20px;

      &:first-child {
        flex: 0 0 100%;
        width: 100%;
      }

      &.cp, &.footer-social {
        display: none;
      }
    }

    &.cp {
      @media (max-width: 800px) {
        width: 100%;
        flex: 0 0 100%;
      }
    }

    &.parents {
      .footer-menu-item {
        margin-bottom: 10px;
      }
    }
  }

  &-menu {
    display: flex;

    &-item {
      display: block;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      margin-bottom: 10px;

      &:hover {
        color: #fff;
      }

      &.parent {
        font-weight: 900;
        text-transform: uppercase;
        font-family: 'Montserrat', 'sans-serif';
      }
    }
  }

  &-end {
    margin-top: 40px;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    .dropdown-social {
      margin: 0 0 40px 0;
      width: 100%;
      justify-content: center;
      align-items: center;

      &__link {
        margin: 0 !important;

        &:not(:last-child) {
          margin-right: 45px !important;
        }
      }

      @media (min-width: 450px) {
        display: none;
      }
    }
  }

  &-social {
    //display: none;
  }

}

.copyright {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  mix-blend-mode: normal;
  opacity: 0.7;
}

.link-social {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  color: white;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  mix-blend-mode: normal;
  opacity: 0.7;

  &:hover {
    color: white;
  }

  p {
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }
}