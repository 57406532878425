.breadcrumbs {
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  &__item {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    color: $main-theme-color;
    position: relative;
    margin-left: 110px;
    padding-left: 30px;

    @media (max-width: 450px) {
      margin: 0;
      padding: 0;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      margin: auto 0;
      left: -100px;
      top: 0;
      bottom: 0;
      height: 1px;
      width: 100px;
      background-color: $main-theme-color;

      @media (max-width: 450px) {
        display: none;
      }
    }
  }

  &__link {
    color: $main-theme-color;

    &:hover {
      color: $main-theme-color;
    }
  }
}