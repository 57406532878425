.section-index-reviews {
  padding: 0 0 30px 0;
  min-height: 800px;

  .container {
    position: relative;
    padding: 0 35px;
    z-index: 3;
  }

  &__title {
    position: relative;
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 70px;
    text-transform: uppercase;
    color: $dark-color;
    margin-top: 90px;
    margin-bottom: 60px;
    //z-index: 3;
  }
}