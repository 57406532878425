.blog-detail-slider {
  &-container {
    margin: 40px 0;
  }

  &-wrapper {
  }

  &-slide {
    border: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &-pagination {
    margin-top: 60px;
  }
}