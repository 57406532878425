@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Roboto&display=swap');

@import "modules/init";

@import "include/variables";
@import "include/common";
@import "include/page";
@import "include/buttons";
@import "include/modal";
@import "include/modal";

@import "include/block/breadcrumbs";

@import "include/block/header";
@import "include/block/header-mobile";
@import "include/block/dropdown-header-menu";
@import "include/block/mobile-hamburger";
@import "include/block/dropdown-social";
@import "include/block/footer";

// главная страница
@import "include/sections/section-sliders";
@import "include/block/index-page-slider";
@import "include/sections/section-index-photo";
@import "include/sections/section-index-service";
@import "include/sections/section-index-portfolio";
@import "include/sections/section-index-reviews";
@import "include/block/index-reviews";
@import "include/block/main-portfolio";
@import "include/block/setuping-form";
@import "include/sections/section-index-blog";

// портфолио
.portfolio {
  .container {
    position: relative;

    &:after {
      position: absolute;
      right: 14%;
      bottom: -9%;
      content: "";
      display: block;
      background-image: url("../../assets/images/a3.png");
      width: 521px;
      height: 650px;
      //z-index: 1;
    }
  }
}

@import "include/block/portfolio-list";

// блог
@import "include/block/blog-list";

// детальный просмотр блога
@import "include/block/blog-detail";
@import "include/block/blog-detail-slider";

// о нас
@import "include/block/about";
@import "include/block/about-slider";
@import "include/block/team";

//отзывы
@import "include/block/reviews";

@import "include/block/header-socials";

#siteInfoToast {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background: white;
  margin: 10px;
  display: none;

  .toast-header strong i {
    color: green;
  }
}

.portfolio-examp {
  display: inline-block;
  height: 600px;
  margin: 20px;
  position: relative;


  &__frame {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;

    &-wrap {
      height: 100%;
      overflow: hidden;
    }

    img {
      width: 100%;
    }

  }

  &:after {
    position: absolute;
    content: '   • • •';
    display: block;
    background: $dark-color;
    //background: rgba(0, 0, 0, 0.3);
    top: -30px;
    left: 0;
    width: 100%;
    height: 30px;
    padding-left: 15px;
    color: $main-theme-color;
    font-size: 24px;
  }

  &-metrik {
    display: inline-block;
    height: 600px;
    margin: 50px 20px;

    &-group {
      position: relative;
      height: 40px;
      margin-bottom: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:before {
        position: absolute;
        content: '   • • •';
        display: block;
        background: $dark-color;
        bottom: -70px;
        left: 0;
        width: 100%;
        height: 35px;
        padding-left: 15px;
        color: $main-theme-color;
        font-size: 24px;
      }

      &:after {
        content: attr(data-name);
        position: absolute;
        top: -24px;
        left: 0;
        font-size: 20px;
        color: grey;
      }
    }

    &-value {
      width: 250px;
      margin: 20px;
      text-align: center;
      color: grey;
      font-size: 20px;
    }

    &-line-l, &-line-r {
      width: 100%;
      height: 1px;
      display: block;
      background: $theme-color-red;
      position: relative;
    }

    &-line {
      &-l {
        &:before {
          content: "";
          height: 6px;
          width: 6px;
          display: block;
          position: absolute;
          background: $theme-color-red;
          border-radius: 50%;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }

      &-r {
        &:before {
          content: "";
          height: 6px;
          width: 6px;
          display: block;
          position: absolute;
          background: $theme-color-red;
          border-radius: 50%;
          margin: auto;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  img {
    vertical-align: top;
  }
}

.float-litera {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 1% 26%;

  @media (max-width: 450px) {
    margin: 0;
    margin-right: -35%;
    height: 80%;
    object-fit: contain;
  }
}

#button-up {
  z-index: 9999;
  width: auto;
  display: none;
  left: 50px;
  margin: 0;
  position: fixed;
  bottom: 50px;
  outline: none;
  color: #333333;
  line-height: 0;
  text-decoration: none;
  padding: 15px;
  font-size: 18px;
  background: #ffffff;
  border-radius: 50%;

  box-shadow: 0 2px 4px #999;
  cursor: pointer;
}

.wrapQuestionReaspekt {
  z-index: 999999999 !important;

  &.hidden {
    display: none;
  }
}

.wrapGeoIpReaspekt {
  color: white;

  span {
    color: white !important;
    border-bottom-color: white !important;
  }
}


.faq_b {
  i {
    transform: rotate(0deg);
    transition: all ease-in-out .3s;
  }

  &[aria-expanded='true'] {
    i {
      transform: rotate(45deg);
    }
  }
}