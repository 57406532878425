.main-portfolio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;

  &__item {
    filter: grayscale(100%);
    transition: filter 0.5s;

    &:hover {
      transition: filter 0.5s;
      filter: none;
    }

    &-wrap {
      flex: 1 1 33%;
      padding: 10px;

      &:nth-child(1) {
        flex: 1 1 50%;

        .main-portfolio__title {
          font-style: normal;
          font-weight: 900;
          font-size: 48px;
          line-height: 59px;

          @media (max-width: 450px) {
            font-size: 32px;
            line-height: 48px;
          }
        }

        .main-portfolio__text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
        }
      }

      &:nth-child(2), &:nth-child(3) {
        flex: 1 1 25%;
      }

      @media (max-width: 450px) {
        flex: 1 1 100% !important;
      }
    }

    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    max-height: 270px;
    object-fit: cover;
  }

  &__title {
    color: white;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }

  &__label {
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}