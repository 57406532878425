body {
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

.container {
  position: relative;

  &.big {
    max-width: 1666px;
  }
}

.liter1a {
  position: relative;

  &:after {
    bottom: -70px;
    right: 500px;
    position: absolute;
    display: block;
    content: "";
    width: 521px;
    height: 650px;
    color: black;
    background-image: url("../../assets/images/a1.png");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1;


    @media (max-width: 450px) {
      background-image: url("../../assets/images/a1m.png");
      right: 0.1%;
      //bottom: -12%;
      background-size: 100% 100%;
      width: 240px;
      height: 450px;
    }
  }

  &-contur {
    &:after {
      z-index: 1;
      background-image: url("../../assets/images/a2.png");
      @media (max-width: 450px) {
        background-image: url("../../assets/images/a2m.png");
      }
    }
  }

  &-white {
    &:after {

      @media (max-width: 450px) {
        bottom: -70px;
      }

      @media (min-width: 450px) {
        width: 481px;
        height: 600px;
        bottom: -100px;
      }
      z-index: 6;
      filter: invert(100%) sepia(95%) saturate(19%) hue-rotate(214deg) brightness(104%) contrast(100%);
    }
  }

  &-red {
    &:after {
      filter: invert(36%) sepia(63%) saturate(4748%) hue-rotate(344deg) brightness(96%) contrast(87%);
    }
  }
}

.swiper-pagination-site {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  .swiper-pagination-bullet {
    opacity: 1;
    background-color: $main-theme-color;

    &:not(:last-child) {
      margin-right: 30px;

      @media (max-width: 450px) {
        margin-right: 20px;
      }
    }

    &.swiper-pagination-bullet-active {
      width: 20px;
      height: 20px;
    }
  }
}

.tags-site {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  a {
    display: block;
    color: $main-theme-color;

    &:before {
      content: "#";
      margin-right: 5px;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.date-site {
  color: $main-theme-color;
  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.sub-title {
  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: $dark-color;
}


.back-layer {
  background: #E2E2E2;
  position: relative;
  height: 700px;

  &-red {
    background: $main-theme-color;

    .breadcrumbs__link {
      color: white;

      &:hover {
        color: white;
      }

      &:before {
        background-color: white;
      }
    }

    .breadcrumbs__item {
      &:before {
        background-color: white;
      }
    }
  }

  @media (max-width: 450px) {
    height: auto;
    background: white;
  }

  & ~ .container {
    margin-top: 250px;
    padding-bottom: 40px;

    @media (max-width: 450px) {
      margin-top: 50px;
    }
  }
}

.stairs {
  &-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $dark-color;
    position: absolute;
    height: 100%;
    padding: 0 15px;
    width: 100%;
    overflow: hidden;
    transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    @media (max-width: 450px) {
    }
  }

  &-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 103px;
    width: 103px;

    @media (max-width: 450px) {
      height: 60px;
      width: 40px;
    }
  }

  &.active {
    overflow: visible;

    .dropdown {
      max-height: 1500px;
      padding: 55px 35px;
      transition: max-height 3s ease-out;
    }
  }

  &:hover {
    transition: background-color 0.25s ease-out;
    background: #535353;
    cursor: pointer;
  }

  &-line {
    transition: all 1s ease;
    background: white;
    height: 3px;
    width: 30px;
    margin: 3px 0;

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  &.active .stairs-line:nth-child(1) {
    transform: rotate(-45deg) translate(-11px);
  }

  &.active .stairs-line:nth-child(2) {
    transform: translateY(-190px) rotate(360deg);
  }

  &.active .stairs-line:nth-child(3) {
    transform: rotate(47deg) translate(-12px);
    width: 32px;
  }
}

.modal-site {
  &-header {
    border: 0;
  }

  &-body {
  }

  &-footer {
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.btn-main {
  outline: none;
  background: $main-theme-color;
  border-color: $main-theme-color;

  &:hover {
    border-color: $main-theme-color;
    background: $main-theme-color-light;
  }
}

.site-form {
  max-width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    width: 100%;
    max-width: 100%;
  }

  &__input {
    border: 1px silver solid;
    padding: 5px;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;

    &.error {
      border-color: red;
    }
  }
}

.form-error {
  color: red;
  margin-bottom: 10px;
}