.detail-blog {
  .page-title {
    color: $dark-color;
  }

  &__preview {
    top: -150px;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    margin-top: 20px;

    .date {
    }

    .tags {
      margin-left: 20px;
    }
  }
}
