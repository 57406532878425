.reviews-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: wrap;
  margin: 0 -10px;


  &-item {
    width: 25%;
    max-height: 300px;
    padding: 10px;

    @media (max-width: 780px) {
      width: 50%;
    }

    &__data {
      height: 100%;
      display: block;
      position: relative;
      transition: background-color 0.5s ease-out;

      &:hover {
        cursor: pointer;

        .reviews-list-item__overlay {
          background: rgba(0, 0, 0, 0.3);
        }

        .reviews-list-item__zoom {
          opacity: 1;
        }
      }
    }

    &__overlay {
      transition: background-color 0.2s ease-out;
      background: rgba(0, 0, 0, 0);
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__zoom {
      transition: opacity 0.2s ease-out;
      opacity: 0;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__image {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}