.section-sliders {
  background: $main-theme-color;
  width: 100%;
  height: 700px;
  position: relative;

  @media (max-width: 450px) {
    height: 650px;
  }

  .container {
    z-index: 5;
    position: relative;
  }
}

