.header-socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  z-index: 9;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: white;

    &:hover {
      color: white;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    i {
      margin-right: 5px;
    }
  }
}