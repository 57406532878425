.section-index-service {
  padding: 150px 0 165px 40px;

  @media (max-width: 450px) {

    padding: 40px 0 165px 10px;
  }

  &__title {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 70px;
    text-transform: uppercase;
    color: $dark-color;
    margin: 0 0 30px 0;
    text-shadow: 0 4px 2px rgba(150, 150, 150, 0.5);

    @media (max-width: 450px) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    &__item {
      margin: 30px 35px 30px 0;
      width: 30%;

      @media (max-width: 450px) {
        width: 100%;
      }

      &:hover {
        .section-index-service-list__title {
          &:after {
            right: auto;
            width: 100%;
            max-width: 300px;
          }
        }
      }
    }

    &__title {
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: $dark-color;
      margin-bottom: 20px;
      text-shadow: 0 4px 2px rgba(150, 150, 150, 0.5);
      position: relative;
      display: inline-block;

      &:after {
        transition: max-width 0.3s ease-in-out;
        position: absolute;
        bottom: -3px;
        left: 0;
        content: "";
        height: 1px;
        max-width: 0;
        background: $main-theme-color;
      }
    }

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $gray-color;
      padding-right: 80px;
      text-shadow: 0 4px 2px rgba(150, 150, 150, 0.5);

      @media (max-width: 450px) {
        padding: 0 !important;
      }

    }
  }

  &__more {
    display: inline-block;
    margin-top: 55px;
    color: $main-theme-color;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    position: relative;

    &:hover {
      color: $main-theme-color;
    }

    &:after {
      position: absolute;
      right: -131%;
      top: 50%;
      width: 96px;
      height: 1px;
      content: "";
      display: block;
      background: $main-theme-color;
    }
  }
}