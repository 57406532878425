.dropdown-social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 80px 0 40px 0;

  &__link {
    margin-right: 100px;
  }
}