.header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $main-theme-color;

  .mobile-hamburger {
    flex: 0.5 auto;
    background-color: $dark-color;
    height: 60px;
    width: 40px;
    position: relative;

    img {
      display: block;
      margin: auto;
    }
  }

  .logo {
    flex: 2 0 auto;
    text-align: center;

    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: white;

    a {
      color: white;
    }
  }

  .phone {
    flex: 0.1 0 auto;

    img {
      display: block;
      margin-left: auto;
      margin-right: 30px;
    }
  }

  @media (min-width: 450px) {
    display: none;
  }
}