.portfolio-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 2;
  position: relative;

  &__item {
    flex: 1 0 20%;
    margin-right: 20px;
    margin-bottom: 50px;

    &.big {
      flex: 1 0 50%;

      .portfolio-list__title {
        font-family: 'Montserrat', 'sans-serif';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 59px;
        color: #FFFFFF;
        text-transform: uppercase;
      }
    }

    &.large {
      flex: 1 0 100%;

      .portfolio-list__image {
        height: 423px;
      }

      .portfolio-list__title {
        font-family: 'Montserrat', 'sans-serif';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 59px;
        color: #FFFFFF;
        text-transform: uppercase;
      }
    }
  }

  &__image {
    height: 271px;
    width: 100%;
    object-fit: cover;
  }

  &__title {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
    text-transform: uppercase;
  }

  &__preview {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }
}