.index-page-slider {

  &-container {
    width: 100%;
  }

  &-wrap {
    width: 100%;
  }

  &-slide {
    padding: 0;
    margin: 0;
    width: 100%;

    &__title {
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 100px;
      line-height: 100px;
      text-transform: uppercase;
      color: white;
      max-width: 600px;
      margin: 50px 20px;

      @media (max-width: 450px) {
        font-size: 70px;
        line-height: 80px;
      }
    }

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      max-width: 600px;
      width: 100%;
      margin: 70px 0 32px 20px;
    }

    &__detail {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      position: relative;
      display: inline;
      margin: 20px;

      &:hover {
        color: white;
      }

      &:after {
        position: absolute;
        right: -120%;
        top: 50%;
        width: 96px;
        height: 1px;
        content: "";
        display: block;
        background: white;
      }
    }
  }

  &-pagination {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .swiper-pagination-bullet {
      background: white;
      opacity: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &-active {
        width: 20px;
        height: 20px;
      }
    }
  }
}