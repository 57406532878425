.section-setuping {
  padding-top: 120px;
}

.setuping {
  &-title {
    @extend .section-index-reviews__title;

    @media (max-width: 450px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  &-label {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 35px;
  }

  &-form {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 100px;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    &__label {
      width: 30%;

      &.has-error {
        .setuping-form__input {
          border: 1px red solid;
        }
      }
    }

    &__input {
      width: 100%;
      padding: 10px;
      outline: none !important;

      @media (max-width: 450px) {
        width: 100%;
      }
    }

    &__error {
      color: red;
    }

    &__submit {
      border: 0;
      outline: 0;
      padding: 8px 18px;
      background-color: $main-theme-color;
      color: white;
      font-size: 22px;
      text-transform: uppercase;

      @media (max-width: 450px) {
        width: 100%;
      }

      &-wrap {
        margin-left: 10px;

        @media (max-width: 450px) {
          width: 100%;
          margin-left: 0;
          margin-top: 10px;
        }
      }

      &-label {
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
