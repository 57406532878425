.index-reviews {
  &-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    &:after {
      position: absolute;
      content: '';
      width: 115px;
      height: 115px;
      background-image: url("../../assets/images/quote.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      font-size: 538px;
      right: 0;
    }
  }

  &-control {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 99999999;
    position: relative;
  }

  &-more {
    color: $main-theme-color;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-right: 25%;
    position: relative;

    &:before {
      margin: auto 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -150%;
      content: "";
      display: inline-block;
      width: 100px;
      height: 1px;
      background: $main-theme-color;
    }
  }

  &-side {
    &:nth-child(1) {
      width: 17%;
    }

    &:nth-child(2) {
      width: 83%;
    }

    @media (max-width: 450px) {
      width: 100% !important;
    }
  }

  &__author {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: $dark-color;
    margin-top: 35px;
  }

  &__organization {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $dark-color;
    margin-top: 20px;

  }

  &__text {
    margin-left: 90px;
    margin-top: 85px;
    max-width: 530px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: $dark-color;
    position: relative;

    @media (max-width: 450px) {
      margin-top: 40px;
      margin-bottom: 50px;
      margin-left: 0;
    }
  }
}