.dropdown {
  max-height: 0;
  background: $dark-color;
  opacity: 0.95;
  color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  overflow-y: scroll;

  .title {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 70px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 40px;
    text-align: center;
  }

  &-container {
    display: flex;
    flex-direction: row;


    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  &-left {
    width: 30%;
  }

  &-right {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .footer-col {
      width: 33% !important;
      flex: 0 0 33% !important;
      margin-bottom: 20px;


      @media (max-width: 800px) {
        width: 50% !important;
        flex: 0 0 50% !important;
      }

      @media (max-width: 450px) {
        width: 100% !important;
        flex: 0 0 100% !important;
      }

      .footer-menu-item {
        margin-bottom: 10px;

        &.parent {
          color: $main-theme-color;
        }
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 45px;

    .close {
      .stairs-group {
        position: relative;
        width: 60px;
        height: auto;
      }
    }

    .title {
      margin: auto;

      @media (max-width: 450px) {
        font-size: 25px;
      }
    }
  }
}

.dropdown-header-menu {
  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #FFFFFF;
  list-style: none;
  margin: 20px 0;

  &__item {
    color: white;
    margin-bottom: 40px;
  }

  &__link {
    color: white;

    &:hover {
      color: white;
    }

    &.active {
      color: $main-theme-color;
    }
  }
}