.section-index-portfolio {
  background-color: $dark-color;
  padding: 150px 95px 95px;

  @media (max-width: 450px) {
    padding: 15px;
  }

  .container {
    position: relative;
    z-index: 5;
    padding: 0 35px;

    @media (max-width: 450px) {
      padding: 0;
    }
  }

  &__title {
    color: white;
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 70px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 60px;

    @media (max-width: 450px) {
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      padding-top: 40px;
      margin-bottom: 30px;
    }
  }

  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 450px) {
      flex-direction: column;
      width: 100%;
    }

    &__item {
      width: 30%;
      text-decoration: none !important;
      filter: grayscale(100%);
      transition: filter 0.5s;

      @media (max-width: 450px) {
        width: 100%;
        margin-bottom: 40px;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:nth-child(2), &:nth-child(3) {
        width: 23%;
      }

      &:nth-child(1) {
        width: 45%;

        .section-index-portfolio-list__link {
          &:nth-child(2) {
            font-family: 'Montserrat', 'sans-serif';
            font-style: normal;
            font-weight: 900;
            font-size: 48px;
            line-height: 59px;
            color: #FFFFFF;

            @media (max-width: 450px) {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }

        @media (max-width: 450px) {
          width: 100%;
        }

        @media (max-width: 450px) {
          margin-bottom: 40px !important;
        }
      }

      &:hover {
        transition: filter 0.5s;
        filter: none;
      }
    }

    &__title {
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      margin-top: 35px;
      text-transform: uppercase;

      @media (max-width: 450px) {
        text-align: center;
      }
    }

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      margin-top: 5px;
    }

    &__image {
      width: 100%;
      height: 100%;
      max-height: 270px;
      object-fit: cover;

      @media (max-width: 450px) {
        height: 270px;
      }
    }

    &__more {
      margin-top: 50px;
      display: inline-block;
      color: $main-theme-color;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      position: relative;

      &:hover {
        color: $main-theme-color;
      }

      &:after {
        position: absolute;
        right: -150%;
        top: 50%;
        width: 96px;
        height: 1px;
        content: "";
        display: block;
        background: $main-theme-color;
      }
    }

    &__link {
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      display: block;

      &:hover {
        color: #FFFFFF;
      }

      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
}